import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/CaseStudyLayout/CaseStudyLayout.tsx";
import Helmet from 'react-helmet';
import CaseStudyIntro from '../components/CaseStudyIntro/CaseStudyIntro';
import CaseStudyConclusion from '../components/CaseStudyConclusion/CaseStudyConclusion';
import NextCaseStudy from '../components/NextCaseStudy/NextCaseStudy';
import shareImage from '../images/DIG-Colors-Thumb@2x.png';
import heartwoodThumb from '../images/Heartwood-Thumb@2x.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet meta={[{
      property: 'og:image',
      content: `https://tinyeahno.com${shareImage}`
    }, {
      name: 'twitter:image',
      content: `https://tinyeahno.com${shareImage}`
    }]} mdxType="Helmet" />
    <CaseStudyIntro title="DIG Colors" subtitle="Creating a color system to improve usability and cohesion across Dropbox's brand presence." heroKey="digcolors" content={[{
      kind: 'text',
      headline: 'The Brief',
      text: "This project began with a simple objective: to implement platform agnostic tokens in the Dropbox Interface Guidelines (DIG) design system. After kickoff, a number of other compelling opportunities became evident."
    }, {
      kind: 'text',
      text: "The previous iteration of DIG was robust, but missing a couple of key features with regard to color. While the Visual Identity System (VIS) created by Dropbox's Brand Studio provided plenty of options, it fell short in terms of support for accessible color pairings and interactive state handling."
    }, {
      kind: 'text',
      text: "Collaborating with my partners in Brand Studio made it clear that we were solving the same problems in different ways, and we were showing early signs of a breakdown in cohesion. The goal of this project grew to include providing a redefined foundation for our global color system across brand and product."
    }]} aside={[{
      headline: 'My Role',
      items: ['Design Strategy', 'Visual Design']
    }]} darkBg={true} mdxType="CaseStudyIntro" />
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.42857142857143%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABV0lEQVQoz32SS0tCURSF+2FNKvoBRT+hgYMGDXoMg4ogcVSDsGaigwhDyR4KJU0qGlQQRaBG9lLINK2b957X19WbIWhuWOx99tpnsdjn9PEXBmO83InuYUwn19ciWqQtNNUvSc2SVC0vf9kGqw2Ns9Kmq+ivIO5Ao9KEzmF4FcY2YHQdRoIwFICBZcOg3639hv4lw1nOE2oJdzhUSjUbu/E9xn3TTMws4pucY2rWTzZfplQTFCvfFCoOr2VFXbSvqZugblpk++KatZUAe6vzxIILbEUXef94wq5L7GodqiWwsiDfvO2afxzqJqFJuHce7nNwmYTnI0pin2zxjvyjRTHzic4+Yr8cIj9vfneoezvcvM1wEE2TC8UppCOEU0kiOwekEsdcRU8px264S4Up5E48Qa16v7LjCgsh0Y6DkQ6iCYGUCiUUxoV2e1rL/1+517/qFd3mfwAPNqWu3UfMQAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/4bd1c5a685f36dbeb2bca1872cf5a2c3/e93cc/Core-Brand-Colors%402x.webp 300w", "/static/4bd1c5a685f36dbeb2bca1872cf5a2c3/b0544/Core-Brand-Colors%402x.webp 600w", "/static/4bd1c5a685f36dbeb2bca1872cf5a2c3/68fc1/Core-Brand-Colors%402x.webp 1200w", "/static/4bd1c5a685f36dbeb2bca1872cf5a2c3/a2303/Core-Brand-Colors%402x.webp 1800w", "/static/4bd1c5a685f36dbeb2bca1872cf5a2c3/4293a/Core-Brand-Colors%402x.webp 2400w", "/static/4bd1c5a685f36dbeb2bca1872cf5a2c3/55654/Core-Brand-Colors%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/4bd1c5a685f36dbeb2bca1872cf5a2c3/aaafa/Core-Brand-Colors%402x.png 300w", "/static/4bd1c5a685f36dbeb2bca1872cf5a2c3/8db2a/Core-Brand-Colors%402x.png 600w", "/static/4bd1c5a685f36dbeb2bca1872cf5a2c3/6ca50/Core-Brand-Colors%402x.png 1200w", "/static/4bd1c5a685f36dbeb2bca1872cf5a2c3/4d07a/Core-Brand-Colors%402x.png 1800w", "/static/4bd1c5a685f36dbeb2bca1872cf5a2c3/ee4de/Core-Brand-Colors%402x.png 2400w", "/static/4bd1c5a685f36dbeb2bca1872cf5a2c3/9391c/Core-Brand-Colors%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4bd1c5a685f36dbeb2bca1872cf5a2c3/6ca50/Core-Brand-Colors%402x.png",
              "alt": "Dropbox Blue, Graphite, Coconut, and accent colors",
              "title": "Dropbox’s core visual identity colors.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Dropbox’s core visual identity colors.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Starting at the Foundation`}</h2>
    <p>{`Dropbox's product suite has always been a branded house, rather than a house of brands. This critical distinction underscored the rationale to use the core brand color palette as a firm foundation for new color system.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.57142857142857%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABW0lEQVQoz5WSW0sCURSF/aX+hqDwB/QS1GNGkIOG5ZhEIVJk5S21i0IKkT1ELxbiJU0dbzk652scM1TQdMNh7TMs1llr9jYxVkIIAzUDBe8VwX5ccPUsCGQEjtjw24gjjMNEmZgqg/TbvxYEK7LGTlBgC/ewyE0yOYNFXxuxxGzBkUO1+81bXiWdhUpDUKhDsa5jTSNbHvZ/j4sFBGtfJax+BbME7gQ4YyryfR9rEPYiguDLWJp5kUeCrUaNw1iLNQ/4HlWc1x+4w3nkB5AiKp5Izog6oC/ksN2s475tYzmGs3SPg0COo5sinqTAHtU4TbSWE1SqZT1yA7MNXLqr3ZCGFNUxDFt+kBNLRu60FE6THdZ9cPGkT9lfRQoqeFP6A3c9zlOdZYfyiT2ksKr/Q7c+jI2TEpveCo44bF92ccWaE7v47x6O3ZhV00JzHQ5Q0wbLO3Qx6KfPrMg/eqNDlMRTJVQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/b753c9b4d2cf207c283bf8f64185a6d9/e93cc/Why-01%402x.webp 300w", "/static/b753c9b4d2cf207c283bf8f64185a6d9/b0544/Why-01%402x.webp 600w", "/static/b753c9b4d2cf207c283bf8f64185a6d9/68fc1/Why-01%402x.webp 1200w", "/static/b753c9b4d2cf207c283bf8f64185a6d9/a2303/Why-01%402x.webp 1800w", "/static/b753c9b4d2cf207c283bf8f64185a6d9/4293a/Why-01%402x.webp 2400w", "/static/b753c9b4d2cf207c283bf8f64185a6d9/55654/Why-01%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/b753c9b4d2cf207c283bf8f64185a6d9/aaafa/Why-01%402x.png 300w", "/static/b753c9b4d2cf207c283bf8f64185a6d9/8db2a/Why-01%402x.png 600w", "/static/b753c9b4d2cf207c283bf8f64185a6d9/6ca50/Why-01%402x.png 1200w", "/static/b753c9b4d2cf207c283bf8f64185a6d9/4d07a/Why-01%402x.png 1800w", "/static/b753c9b4d2cf207c283bf8f64185a6d9/ee4de/Why-01%402x.png 2400w", "/static/b753c9b4d2cf207c283bf8f64185a6d9/9391c/Why-01%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b753c9b4d2cf207c283bf8f64185a6d9/6ca50/Why-01%402x.png",
              "alt": "Several approaches to implementing state support for primary buttons",
              "title": "A handful of approaches we explored to add hover and pressed states to DIG Buttons.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`A handful of approaches we explored to add hover and pressed states to DIG Buttons.`}</figcaption>{`
  `}</figure></p>
    <h2>{`How Should States Look?`}</h2>
    <p>{`Interactive state support was lacking in VIS. This led to a lot of redundant and slightly different effort to articulate hover and pressed states across the DIG component library. We started to see uncanny differences between components, as well as between logged-out and logged-in surfaces.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "119.94609164420484%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAYAAAD6S912AAAACXBIWXMAABYlAAAWJQFJUiTwAAAFPElEQVQ4y4WVaVNT2RaGA+QkZ848kIQEEqZAhDAPIqMoKEFFCNLYoBEhTYPazCooRGlAe/hXXdW/6rk7AS5V3VX3fnhydp06eWu971p7b4uuaWiqSnmZpUSFQCq3YKuwYLdakAWqZEGzWdDtRcrR5QqBFV2RBHYMTUHX1JKORZFlPB4PmekZRsanGBh7ROedDC39j6jvzBBNZwjcmsFoeIxc8xC5ahg5fBs50Irsa0J2VaO7o+i6cSmoKgoup4s7gyN09w3R3jNMqmOIhvQI1akhQo3D+KrbMcNtKFWDKJVdKME0ircRxZNAcUZQzACapqMXBa8tS9YKrOVlSBVl2Kxlwm4ZslSOKixL9c+xNyxhCNuGYr3ChqHahIiMQ9e41rEUf4qYhoHDNDGLGFeYDkxVoiLzF+XTfwsBCUO8LyEsXqPrOtc6pQr/J6oI3OG7pLj+P99bDKFeDLRY3T8r/G81Vx//u7LiUy9VaFxhKbbbqdmoKOVXLsblKj+BahMZSmU4hFWnJmHKZZgivxIiP1O1Y2qyQL2xLOtuXLeesfTiNU8XXvEgu8rQ9ArdD9dIja1Qc2eFYF8evW0Va1MOW91TpPgUtsgAtspu7N5kaXQ0zbjssuL0Es9ucPLbn2x8+Ub28zcGdr6SfPOF2tw+1c/eUPV4jeD4c7yDWVxdkzjTo2Iuu9DjLaghMTre0M3Y+HWZ3YEEv38t8O1gh9/e5jnLZblYuMfXB20UBqs57vCw1WyQbzSYT+hMV+sMhjW6AypJj0LMIYuci5ZFU3xOO+tzXn798gvHH55ytNPOfr6Gg5c+3s3ayT8s58V9K7ODVqZ6JYbTEv0piZaEjWRMIhaUCHqkG0GXx8Hc62mOTz+ydZRn7f0ci28zzK5nmFieYHj+Lv1PRmi/30/LSCfJnhR1bQ3EGmOE42H8IR8ur/NmsD0uH7lnu5yf/iEq/M7h9p9s5c/ZfPkrr+YOWZzaIzu+xVR/nnsdr7idmqWraYKmRA+10RbCwRp8nqAQvBxuS8Dl4OMPk3wvHHKx9zPf3/woMszwbWGUQqaXw7E0B7eb2EzHWWmOMdMQZaIuQm8sSGvET9zvplJoGNcVOl0GmR8HOD97z6ej1+wdzLL6ZozsT/2ML3dzZ76Dnpk0rZMpmsaSJPpqiXXWUNkUwV8bxBXxYviE5eJ+Lu1lWacl3kuhcMr+wWfebn0il99n7uUek3MbTEzmmBxa5H7LFGO1o/RHe+kMtdPsa6DeHSdqhglqPrHPRYWiMRa3OCCXkyF+Pzvl/PCAi51NTteWOFt+QkE04/humpO+BLu3wmzU+3keczMbcXDXbzDg0WhxKCR0++VuKe5lRSxi7Y0cnxyzc7DH+vZbFtdXyKw9p2/pEa3ZezQ/GSZxv5foaAeVvSn8HY24k9U4aiPoIkfV70K7tqwqGs2NPVycX/D50wkf9o94t7HD+uovLP/wE/MzK8w8yDExvMhI3xw96UnaU2M0JrqJR1sJBWrxuiM3XXbJJtn4GGcnXznZPeJk8wMfc1t8XHjH7lSe7eEc211LbDaIZsUyPAuN8jgwwLCnjT5nM81GDTVapTgo9MsMXeK0mO+q4eK0QOH9NoXtVQ7XnvJpeYj9mRTvJuv5eTTBi54qFtKVPEz6GK/z0BN1kg6Z1Pk0Ik7lZqfo4sbyuFWiVRFi0QjRSFDgJxp2U1VpEhGEBaGgSTBgEAg48HkN/D4HXo8p1k7xf0fpLLy6AjSKOcp2meINqMiKQEUR7xRFL6FeU7RVQrt66qXsLrkc7P8AfDPOFaU8LHAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/ca553413fed4ca9d23e36ea189016e99/e93cc/How-01%402x.webp 300w", "/static/ca553413fed4ca9d23e36ea189016e99/b0544/How-01%402x.webp 600w", "/static/ca553413fed4ca9d23e36ea189016e99/68fc1/How-01%402x.webp 1200w", "/static/ca553413fed4ca9d23e36ea189016e99/a2303/How-01%402x.webp 1800w", "/static/ca553413fed4ca9d23e36ea189016e99/4293a/How-01%402x.webp 2400w", "/static/ca553413fed4ca9d23e36ea189016e99/d1b65/How-01%402x.webp 2968w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/ca553413fed4ca9d23e36ea189016e99/aaafa/How-01%402x.png 300w", "/static/ca553413fed4ca9d23e36ea189016e99/8db2a/How-01%402x.png 600w", "/static/ca553413fed4ca9d23e36ea189016e99/6ca50/How-01%402x.png 1200w", "/static/ca553413fed4ca9d23e36ea189016e99/4d07a/How-01%402x.png 1800w", "/static/ca553413fed4ca9d23e36ea189016e99/ee4de/How-01%402x.png 2400w", "/static/ca553413fed4ca9d23e36ea189016e99/2d04e/How-01%402x.png 2968w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ca553413fed4ca9d23e36ea189016e99/6ca50/How-01%402x.png",
              "alt": "Scaled colors from Dropbox's VIS core colors",
              "title": "Core Dropbox VIS colors scaled based on luminance.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Core Dropbox VIS colors scaled based on luminance.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Luminance`}</h2>
    <p>{`After several rounds of discovery, I landed on luminance as the crux of scaling our color palette. Luminance is the most objective measurement of color contrast. Using this approach provided the highest level of confidence that users would be able to distinguish the colors in our interfaces, regardless of individual color deficiency (i.e. color blindness).`}</p>
    <p>{`By establishing minimum and maximum luminance targets and a standard set of steps, the DIG color system is able to provide reliably distinguishable color groups. By using core VIS as the foundation, we were able to maintain cohesion without reinventing the wheel.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.57142857142856%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABYlAAAWJQFJUiTwAAADE0lEQVQ4y21USU9TURQuC5YEQ9gAgdQASgRMYCEm6k5+gYlRkVFM1MRhYUSo2ErcGMIKE0M0ThtkRyJlKDKFsBAQSEQMWgcMtFTavvn1vQ58nns7UIhNvpze++793nfOd86zqLIEVZEJEoeiajAMA6puQtEihDCPqh5FyIhCN2IwIoCu69DonqYqKeiaCgsnJGiqDDEYQEdHJy5erENDYzMu1TXickMTmlqu0voKLjS0or6pBTdvN2NqegyxCL1QFhPEMnQitXBVRMgWwcAOjldWIMOSgfy8AhQU5CMn5xBstg58GHOh69Ugel48Q//zbqwsf0QkbPLs9itMELINIeDHmdOnkJ2djSOlR1FWVoaiokIMOZ1gvzl/FPN+LxTPIgT/H6h0J16uuEItrlDmKesaEQb9OFlTg6ysLBQXl6C0tAR5eXl47xzihPfG3XjsHMTsuydY/TKPkK5BkQSCyEUxLk6oJAlJYWVFBTIzM1FYWATrYStyc3MxOjrCCc/3zeBOtwNvO69jdm4KphHiNUwaG1cop6VMptTWnoXVakV1VTWqqqpQXn4MAwP98G76cM7+Etfu3oC9tR4z05P8JUrC0HgNE4SplqH/3q1NrCx9wgipGne54KR0R4adhGEe2XqY4uTEBBYXFvg9RrS/bVJ9KPMUwqYBF5G1t7eh65EDDocdD2w2PO3tJbUDtH8fdvtDrK2tcRJlX8oJl5JOSWIQJhHKkgT392/46V7nWP/6Gb5tD7a9W3Cvr8Kz+TtuCitZyhTpACFTSA/ZwUjEpN6UsPHLTfsCfBs/oNOFWCyKv2IYIXOXmyKnkbFs01JOgNahkM7T1hWRlGzQngBV9MPQqF5GGLfeAM7lXbLEgCjuuczE/LeGRijEp0ASAzB0NkVsvCRETQ0azbjlxGt09i1xlwVB2Gts3odpcllkKbADrIY+nwc72xvwb29R9EIW/KRIQJu9B87RKdAw83PcYQIrlSXpUJKQKWTSJTE+AfJB0D6wi4ip8zPxHpT32ia5SEI98ElKf64nosSNkEmRyknS8Q+0r09ezdXNmQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/477cd23f5a28ffa2d36392ebbcbc7d75/e93cc/Prototyping-01%402x.webp 300w", "/static/477cd23f5a28ffa2d36392ebbcbc7d75/b0544/Prototyping-01%402x.webp 600w", "/static/477cd23f5a28ffa2d36392ebbcbc7d75/68fc1/Prototyping-01%402x.webp 1200w", "/static/477cd23f5a28ffa2d36392ebbcbc7d75/a2303/Prototyping-01%402x.webp 1800w", "/static/477cd23f5a28ffa2d36392ebbcbc7d75/4293a/Prototyping-01%402x.webp 2400w", "/static/477cd23f5a28ffa2d36392ebbcbc7d75/55654/Prototyping-01%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/477cd23f5a28ffa2d36392ebbcbc7d75/aaafa/Prototyping-01%402x.png 300w", "/static/477cd23f5a28ffa2d36392ebbcbc7d75/8db2a/Prototyping-01%402x.png 600w", "/static/477cd23f5a28ffa2d36392ebbcbc7d75/6ca50/Prototyping-01%402x.png 1200w", "/static/477cd23f5a28ffa2d36392ebbcbc7d75/4d07a/Prototyping-01%402x.png 1800w", "/static/477cd23f5a28ffa2d36392ebbcbc7d75/ee4de/Prototyping-01%402x.png 2400w", "/static/477cd23f5a28ffa2d36392ebbcbc7d75/9391c/Prototyping-01%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/477cd23f5a28ffa2d36392ebbcbc7d75/6ca50/Prototyping-01%402x.png",
              "alt": "Screenshots of a Codesandbox prototype feeding into a Figma plugin",
              "title": "Codesandbox + Figma Plugin API.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Codesandbox + Figma Plugin API.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Prototyping for Scale`}</h2>
    <p>{`Due to the sheer volume of colors we could generate with our luminance-based approach, it was necessary to carefully consider tooling. The solution was to use `}<a parentName="p" {...{
        "href": "https://gka.github.io/chroma.js/"
      }}>{`Chroma JS`}</a>{` to generate the color scales. On the design side, I built a custom Figma plugin to import the results as styles (and later variables). On the engineering side, my partners were able to implement identical logic in Storybook for cross-platform compatibility.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABUUlEQVQoz32Q63KCMBCF8xydekHU1vGurbW+/yvoP3qzdbAqEgFFIJxmgzDWSjNzJstmz5cTWBwLxHGcq8v13yyxWJ6JlhAxwjBEFEVqJ6VneT52PnCeSgiBrWXBMAyYponpdIrZbAZ7u1UXXEtInqsJgyDA8eiroUiIP2mEBJL52mLi4jYhIviH/a9n0b9xXReO4yhRbW3W4JyfervsjO3sDTyH4+Dt4O/lMLfkt63qvcsR+B7W3yYmz09oNRvoddvotFvo9zoY9LuybqLbaan+cNADI9DB48pMIG6t4KhLbNmzFXC1XEjYPQq3N9DKRVS0InSthLu6LusSyqVCJkYggpL58/0Fy8UcXx+vmL8ZWUICUgKC1Ws6JuNHPMg049FQ1iNUdS1TBiRRqiSZTHzaUyA9ixKQqVatKKU17XqlnACTdMmTU3CqPCCZL5X2fwBsypHGWlNKdgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/66eb2d3d2b5ca41ac40c938463b46ad3/e93cc/Theming-01%402x.webp 300w", "/static/66eb2d3d2b5ca41ac40c938463b46ad3/b0544/Theming-01%402x.webp 600w", "/static/66eb2d3d2b5ca41ac40c938463b46ad3/68fc1/Theming-01%402x.webp 1200w", "/static/66eb2d3d2b5ca41ac40c938463b46ad3/a2303/Theming-01%402x.webp 1800w", "/static/66eb2d3d2b5ca41ac40c938463b46ad3/4293a/Theming-01%402x.webp 2400w", "/static/66eb2d3d2b5ca41ac40c938463b46ad3/55654/Theming-01%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/66eb2d3d2b5ca41ac40c938463b46ad3/aaafa/Theming-01%402x.png 300w", "/static/66eb2d3d2b5ca41ac40c938463b46ad3/8db2a/Theming-01%402x.png 600w", "/static/66eb2d3d2b5ca41ac40c938463b46ad3/6ca50/Theming-01%402x.png 1200w", "/static/66eb2d3d2b5ca41ac40c938463b46ad3/4d07a/Theming-01%402x.png 1800w", "/static/66eb2d3d2b5ca41ac40c938463b46ad3/ee4de/Theming-01%402x.png 2400w", "/static/66eb2d3d2b5ca41ac40c938463b46ad3/9391c/Theming-01%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/66eb2d3d2b5ca41ac40c938463b46ad3/6ca50/Theming-01%402x.png",
              "alt": "A token for subtle text color mapped to different values depending on whether dark or bright mode is being used",
              "title": "The same token mapped to bright and dark mode.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`The same token mapped to bright and dark mode.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Semantic Tokens`}</h2>
    <p>{`Before all the foundational scaling work, we already had a layer of semantic, role-based color tokens. The next step in this effort was to update the semantic tokens to provide straightforward, no-fuss support for theming and modes.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.71428571428572%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAADA0lEQVQ4y62R32scVRTH502sgsbMj3tnZ3cn2d9J28T6o4qQKCpq8UERg6G0WgpaRB988m/om/juq+KDiqJEEQRDRCgqlFJ9KBYiNtrN7GyyP7LZ3Zn9eGems8nqm/TCl3POPd/7Pefco6HOaDQisamfxqMxUsKYy2Qu9bWDR+EhoYMiE8L/ig/nU6ull/1+n4a/y3A4jOPIep5Hp9OJ48FgQHtnN6mmTrfbxW82/yOqdff2Ieiyfg3ct+ucufgLgUpcvforp559hjfOn6PR2qH+8298uXiaK8+/C619Plj7jOpbK1xc+4i+KjYeOQijUUPqbfjwhz2+v+zFlXZ2d/l6bY2N9XUGwZB91f3mJ9/hf3NJNTDi2o0/+HjjW678eX3ir7VolE67TXtvwGYDft9OpgqViO/VadS3CFXRURDSu+nT3UoI3X6PTe9vbux4k0sJgoChEu31A7aaIX8phCoR/WHTb+A3tmPBcBjQ227Sq/uMwhF7/X22fA+vk/xrqJY6sZTbdbRWq8XthFYuFUlROuT/X2imoRPBMg0sY1r507diHUNP/DRvKBvBiu8MTN0Y51NothQIy8TN5yhXamSdDNIyyGUdjs7PUSzMxmK5XJZaqUBG8S3LojA7Q7VaxJZS8QUZWxBpaa7rMpNVgideIHvhOnctvYduSh5fXuanSz/y1eefUq5WeGqhyuV3VthYPUnJyLD6puT9L0xOvaKTz0diVgwtY9vYwsCZmUOefB29/HTccblU4rWzZ1h56UUcx6HiZjn/2AKnTxRxVMH7HxY897LJ/KKJbR8SzGWzlAsuwj3OHbWz3Dn3KlKN4SjSfK3CIw8uYmdsSlJnxb2HVfduMmrEYu0+Hn3yCMcfmkKYEx1Kco5EOAXuLTzB1OwSUgglKKlVyiwcnVMdSFxFXsobLOd1bCU4UzA49sAUpaoe/+FYUAqLCNHYGWsqRpSI7qJlRNuMfKGKTKuH08pKlRfKN3WpOOIWX8SItxxBxpAJRHI3CUttOIEtDjpKxVL/H7ELrEFl1TGUAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/ba49c0272805ac10e1e4d1ffea06f90c/e93cc/Color-Groups-VIS-2020%402x.webp 300w", "/static/ba49c0272805ac10e1e4d1ffea06f90c/b0544/Color-Groups-VIS-2020%402x.webp 600w", "/static/ba49c0272805ac10e1e4d1ffea06f90c/68fc1/Color-Groups-VIS-2020%402x.webp 1200w", "/static/ba49c0272805ac10e1e4d1ffea06f90c/a2303/Color-Groups-VIS-2020%402x.webp 1800w", "/static/ba49c0272805ac10e1e4d1ffea06f90c/4293a/Color-Groups-VIS-2020%402x.webp 2400w", "/static/ba49c0272805ac10e1e4d1ffea06f90c/55654/Color-Groups-VIS-2020%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/ba49c0272805ac10e1e4d1ffea06f90c/aaafa/Color-Groups-VIS-2020%402x.png 300w", "/static/ba49c0272805ac10e1e4d1ffea06f90c/8db2a/Color-Groups-VIS-2020%402x.png 600w", "/static/ba49c0272805ac10e1e4d1ffea06f90c/6ca50/Color-Groups-VIS-2020%402x.png 1200w", "/static/ba49c0272805ac10e1e4d1ffea06f90c/4d07a/Color-Groups-VIS-2020%402x.png 1800w", "/static/ba49c0272805ac10e1e4d1ffea06f90c/ee4de/Color-Groups-VIS-2020%402x.png 2400w", "/static/ba49c0272805ac10e1e4d1ffea06f90c/9391c/Color-Groups-VIS-2020%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ba49c0272805ac10e1e4d1ffea06f90c/6ca50/Color-Groups-VIS-2020%402x.png",
              "alt": "Bright and dark mode variants of standard color groups for primary, secondary, alert, and success UI",
              "title": "Semantic color groups.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Semantic color groups.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Color Groups`}</h2>
    <p>{`In order to simplify to semantic layer, we implemented some common concepts and terminology to make adoption and usage as straightforward as possible. Base and surface became useful distinctions between selected and deselected states. State modifiers provided support for hover and pressed states. Group names such as primary, secondary, alert, and success could then be tied to specific use cases and statuses.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.14285714285714%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABbUlEQVQoz52QyU7CUBiF+wQyyFTAWXCrRo3Dy8jK6AOY6MaFO3fGpUujISoU0fgOxgULQwgpgsQFiQbsQCtTe+y9oaRlWtjkS3NPe7977s/IYg3DkIQqGqqMIp8Hz+fQbqpQ6gJG/W/CjPuoKCJOD49xdnSCfPYNn6V3etD/hbKA2/gNLs8vkMtkkH15pblQ+x4JQ04cB/Q2SkUeP9UvfJQKMAIjagBacyjMryLDRFWkLnIPpS5Rnp/SeExz4JJ3eOASSHH3SCUHYTDm0XWdvkVRxOzMNJyOCfi8HrhdTricDngm3QMwZJO5UVVVyJIETdMoZi4Z2VI0Ar/Pi1CQxcL8HKKRRYRDQboOsoEeNmEstouD/T0UCjwqlYpNGO0KScOHVALx6yvsbG9hdWV5tLBcLlNZq9VCp9OxC41GRMYG/NhYXzNkm5gKh2hLm7B/XsNmaBWSK5rzChhytg9bQ+vsrLlVSFoQaf/sTP4A61KrRzAMBW4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/ac1dfeaa5277e03080669fdc80fa3891/e93cc/Turnkey-Component-Example%402x.webp 300w", "/static/ac1dfeaa5277e03080669fdc80fa3891/b0544/Turnkey-Component-Example%402x.webp 600w", "/static/ac1dfeaa5277e03080669fdc80fa3891/68fc1/Turnkey-Component-Example%402x.webp 1200w", "/static/ac1dfeaa5277e03080669fdc80fa3891/a2303/Turnkey-Component-Example%402x.webp 1800w", "/static/ac1dfeaa5277e03080669fdc80fa3891/4293a/Turnkey-Component-Example%402x.webp 2400w", "/static/ac1dfeaa5277e03080669fdc80fa3891/55654/Turnkey-Component-Example%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/ac1dfeaa5277e03080669fdc80fa3891/aaafa/Turnkey-Component-Example%402x.png 300w", "/static/ac1dfeaa5277e03080669fdc80fa3891/8db2a/Turnkey-Component-Example%402x.png 600w", "/static/ac1dfeaa5277e03080669fdc80fa3891/6ca50/Turnkey-Component-Example%402x.png 1200w", "/static/ac1dfeaa5277e03080669fdc80fa3891/4d07a/Turnkey-Component-Example%402x.png 1800w", "/static/ac1dfeaa5277e03080669fdc80fa3891/ee4de/Turnkey-Component-Example%402x.png 2400w", "/static/ac1dfeaa5277e03080669fdc80fa3891/9391c/Turnkey-Component-Example%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ac1dfeaa5277e03080669fdc80fa3891/6ca50/Turnkey-Component-Example%402x.png",
              "alt": "A toggle component in bright and dark mode with the same tokens applied",
              "title": "A Toggle component with semantic tokens applied.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`A Toggle component with semantic tokens applied.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Turnkey Theming`}</h2>
    <p>{`Previous attemps at theming and mode support had a nasty habit of requiring different semantic tokens to be applied per theme/mode. While this could be made to work, it created a lot of extra work without a lot of extra value. A goal of this effort became to guarantee that tokens could be applied once and theme or mode could be changed independently without causing contrast issues.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "124.78571428571428%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAYAAAAxFw7TAAAACXBIWXMAABYlAAAWJQFJUiTwAAAEGklEQVQ4y31UW0/jZhDlre22WiCBXBySkAChQFEpG1bitiy3IBbaVVntVlSo7DtPVfveqv+h+3Oqqk/8hu1bSwmF+BLbcRI79unMOE5Cq66l4/F8/ubMmZnPHgJdnU4HbseH7wcIggBk0KFbCLwTXicQ8F6+hphAqzu4M5qoqQbuCH/d2riutVHVXFTVd6EN1fShWgHqjVCQEOqaCl29Q93Q4TQasO0GHKdJyn14XocUdK3Xt67YDpH4sEyT9jt9hYZWw021iuurP+B7bbx58zO+evUSV1d/SoBtW2i322hQMtd1xXKbgLBFnueR78lzqJDUqaoOTa3BbTn4/e1bXF5eEpEthBzM5XSkz5HvC0F0Se9pbYhvLceGYdRh6BraTSq3YcOyTOjku6TMJ4KO54oKX5T1Lya/qV7DI+WiMCS0KLiO25sqHNuEpmnU1xpsq44WJRA4oW23mtIvLlMSEWxKzonvEbLC279v4FgGdMtDq+2FpQD/UWTSEDiOSS3LkgRRG4Sw2bBowibUu9tQreVS/xwEftizCNEAbCKJ1prNpgzsPmFXoQyFytLMNjTdEMKo2YOEVpeQ/QjRnp5CrdvDpl2nw0oHVtMpuyPT/TehadbFDhINKAyk2fW6iZqUbMNseKKQA3njoBL2+RwOrt1TyA4HarpOpHR0DEOmrNGRYauqKqntWkF3fWCtVqtJrEyZM37/3bf48sUrnJ2d4fT0FMfHx9jf30fl4AAH/wN+d3h4iC+eP0elUsHFxYUMZ4g/JX6RTIxDUdKIx+OYmZ4S8Fofifs2mUAqmUQ+l8VERsHTrSdyfIRwb3cHufwkFhbmERsdwU8//oDffv0F5UfLyFCS6amiBE4VCz2bnVDkHYNjNjfW5QgNuTStyv4elIkc8vm8vHy8UpYkrDI7kRFwYGRz2QmxSjol6kaGH2JjfS0k5PGvra1iLJFCNptFLDaK2dIMFubnsfjJAgqTeSHI53Kijp9TVG46lRRkiLCQz2L76VZYMhOuE3silUGxWMTYWAxzc/P4eHZWSmNCRrEwKXYyn5NnVpbLZqQKTrL1ZHOQcBUpJYtSqYTx8RgWlx5TcAGJ8bGeEgYr41KZhC0rfrS8RIknqeT1fsmscDypoDg1hXhsBJ8ur1LZs9KjqPFRz7iPTBj1j585UW8oTLi5uYFkinpRKFAPh/FZeQ2Li4sUkJagQbCq0syMELPPdiwek5IjwqBcXg4+ejgapBUl+PDBe0FpbimYnp4OlHQyoKn3MDoyHFBwQCRBPDba8x988H6wslIOqIeB/BzOz7/B9m4FR/SFbG6s4cXLr3FycoLPj4/oi9nD7s42YUfs/t4ujp49k2MV+Vzu69fn4ZdC/0yv1Wp5JFdAWQT0AxBE/iAG90VgDub6B4HPuhIWGlaxAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/06c40d3dde3b05bdbe631488b90e8774/e93cc/Mode-And-Elevation%402x.webp 300w", "/static/06c40d3dde3b05bdbe631488b90e8774/b0544/Mode-And-Elevation%402x.webp 600w", "/static/06c40d3dde3b05bdbe631488b90e8774/68fc1/Mode-And-Elevation%402x.webp 1200w", "/static/06c40d3dde3b05bdbe631488b90e8774/a2303/Mode-And-Elevation%402x.webp 1800w", "/static/06c40d3dde3b05bdbe631488b90e8774/4293a/Mode-And-Elevation%402x.webp 2400w", "/static/06c40d3dde3b05bdbe631488b90e8774/55654/Mode-And-Elevation%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/06c40d3dde3b05bdbe631488b90e8774/aaafa/Mode-And-Elevation%402x.png 300w", "/static/06c40d3dde3b05bdbe631488b90e8774/8db2a/Mode-And-Elevation%402x.png 600w", "/static/06c40d3dde3b05bdbe631488b90e8774/6ca50/Mode-And-Elevation%402x.png 1200w", "/static/06c40d3dde3b05bdbe631488b90e8774/4d07a/Mode-And-Elevation%402x.png 1800w", "/static/06c40d3dde3b05bdbe631488b90e8774/ee4de/Mode-And-Elevation%402x.png 2400w", "/static/06c40d3dde3b05bdbe631488b90e8774/9391c/Mode-And-Elevation%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/06c40d3dde3b05bdbe631488b90e8774/6ca50/Mode-And-Elevation%402x.png",
              "alt": "Dropbox browse rendered in bright and dark modes",
              "title": "Examples of the browse feature in bright and dark mode.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Examples of the browse feature in bright and dark mode.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Mode and Elevation`}</h2>
    <p>{`Prior to this effort, Dropbox didn't ship dark mode to web. Before wrapping the project, we reevaluated and established an approach to mode that would maintain elevation between bright and dark mode. This meant that any thing less elevated (further away from the global light source) would be darker, regardless of mode. This helps the interface feel consistent across modes.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "93.78571428571428%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAABYlAAAWJQFJUiTwAAABcklEQVQ4y62Uz0sCQRTH91/00F8QnbrZwVOChyC0CDoFdQq6Rdgp0Cgls2QNaqmIishslUqNtDzIzH7aXU3X3HUtffCYefPjO2++33mjJANz5MNrGIaBZT/tf01xBk4wq+/nbgkoXmB+ZoO6ATpPGwCz5zrdTiztAW96XK8shCC0scr68hQ7oUXigXlmVzZJRLaJBmPsL0wj3p46e6Q/oNWmtTM0dZfbVI67+BGJ/BWPmRtOD3IUTvaQzcZwDv/C37C1ijRa5oQYUFZIiZQCKczWpMCORcfNcacoTneoLJmEKWoxgv6RsoPuyWO48lDbotbUulmODeh1ZYvXtpsLLf76NkrwEM8URXTBehtGUPhXtQxk2H0G7ZKgVE/TqCZ5f67QVI9Rn3WqepnXcoWvQh5an+6l5w4oyRaCFK9nuE9f8BINEztMcZnJcZ7VKCWWoK77A7ZzG/Nhe5WenazR44k+UfD/vnpXNkb6D/H6vpiwfQO7WcpahBcClwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1f6ce65d48d009329a72366fdef81829/e93cc/Identity-Colors%402x.webp 300w", "/static/1f6ce65d48d009329a72366fdef81829/b0544/Identity-Colors%402x.webp 600w", "/static/1f6ce65d48d009329a72366fdef81829/68fc1/Identity-Colors%402x.webp 1200w", "/static/1f6ce65d48d009329a72366fdef81829/a2303/Identity-Colors%402x.webp 1800w", "/static/1f6ce65d48d009329a72366fdef81829/4293a/Identity-Colors%402x.webp 2400w", "/static/1f6ce65d48d009329a72366fdef81829/55654/Identity-Colors%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1f6ce65d48d009329a72366fdef81829/aaafa/Identity-Colors%402x.png 300w", "/static/1f6ce65d48d009329a72366fdef81829/8db2a/Identity-Colors%402x.png 600w", "/static/1f6ce65d48d009329a72366fdef81829/6ca50/Identity-Colors%402x.png 1200w", "/static/1f6ce65d48d009329a72366fdef81829/4d07a/Identity-Colors%402x.png 1800w", "/static/1f6ce65d48d009329a72366fdef81829/ee4de/Identity-Colors%402x.png 2400w", "/static/1f6ce65d48d009329a72366fdef81829/9391c/Identity-Colors%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1f6ce65d48d009329a72366fdef81829/6ca50/Identity-Colors%402x.png",
              "alt": "Placeholder avatars were punched up with this effort",
              "title": "Identity colors before and after the system overhaul.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Identity colors before and after the system overhaul.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Identity Colors`}</h2>
    <p>{`The tail end of this project dovetailed with a larger visual refresh effort. This presented a handful of opportunities to further fine tune our color system. Most Dropbox users don't upload a profile picture, so we saw placholder avatars and their identity colors as an opportunity to fine tune and inject a little more branded appearance into the product.`}</p>
    <CaseStudyConclusion headline="Results" text="The work I did on DIG colors unlocked several valuable results. First and foremost, this effort enabled a cross functional tiger team to ship dark mode support to dropbox.com — one of the most user requested features in recent years. The redefinition of the color system also improved the accessibility of Dropbox products, unlocked the foundation of a themeable component library, and enabled our design team to evolve the product suite without breaking down cohesiveness across it." mdxType="CaseStudyConclusion" />
    <NextCaseStudy slug="heartwood" image={{
      src: heartwoodThumb,
      width: 736,
      height: 460
    }} title="Heartwood" description="The operating system for brick and mortar businesses." tags='Design Strategy, Digital Product Design, Visual Design' mdxType="NextCaseStudy" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      